<script setup lang="ts">
import { ref } from "vue";
import { useWeb3 } from "@riseworks.io/web3";
import connect from "./connector/connect.vue";
import connecting from "./connector/connecting.vue";

const props = defineProps<{
  email: string;
}>();

const emit = defineEmits(["close"]);

const step = ref<"connect" | "connecting" | "processing">("connect");

const web3 = useWeb3();

function close() {
  step.value = "connect";
  emit("close");
}

const walletType = ref("");
async function connectWeb3(type: any) {
  walletType.value = type;
  step.value = "connecting";
  try {
    await web3.connect(type, props.email);
    close();
  } catch (e) {
    console.error(e);
    step.value = "connect";
  }
}
</script>

<template>
  <Teleport to="body">
    <div
      class="fixed left-0 z-[4000] top-0 flex h-screen w-full items-center justify-center p-6"
    >
      <div
        class="absolute left-0 top-0 h-full w-full bg-black/80"
        @click.stop="close"
      />
      <div
        class="relative max-w-[358px] rounded-2xl bg-white dark:bg-gray-900 p-6 text-gray-900 dark:text-white"
        @click.stop
      >
        <connect v-if="step === 'connect'" @next="connectWeb3" />
        <connecting v-if="step === 'connecting'" :type="walletType" />
      </div>
    </div>
  </Teleport>
</template>
